<template>
    <CRow>
        <CCol class="col-xxl-6 col-12">
            <CCard v-if="user">
                <CCardHeader>
                    <strong class="text-value-lg mr-2">{{user.name}}
                        <CIcon v-if="user.verified === true" name="cil-check-circle" v-c-tooltip="'verified'"/>
                    </strong> <em>@{{user.screen_name}}</em>
                    <div class="float-right"><a :href="'https://twitter.com/'+user.screen_name" target="_blank">
                        <CIcon name="cil-external-link" v-c-tooltip="{content: 'got to profile on Twitter', placement:'left'}"></CIcon>
                    </a></div>
                </CCardHeader>
                <CCardBody>
                    <div class="d-flex py-3" v-if="user.json">
                        <div class="flex-column pr-3">
                            <CImg :src="getFullProfilePicUrl(user.json.profile_image_url)" thumbnail shape="rounded-circle" alt="" width="200" height="200"/>
                        </div>
                        <div class="flex-column flex-fill pl-3">
                            <p class="mb-3">{{user.description}}</p>
                            <span class="mr-1 d-block" v-if="user.json">{{user.json.location}}</span>
                            <slot v-if="user.json.entities && user.json.entities.url"><span class="mr-1 d-block" v-if="user.json.entities.url.urls"><a target="_blank"
                                                                                                                                                       :href="user.json.entities.url.urls[0].expanded_url">{{user.json.entities.url.urls[0].display_url}}</a></span>
                            </slot>
                            <span class="mr-1 d-block">Seit <span class="text-value">{{getDateInFormat(user.json.created_at, 'P')}}</span> bei Twitter</span>
                            <span class="mr-1 d-block"><strong>{{user.followers}}</strong> Folge ich</span>
                            <span class="mr-1 d-block"><strong>{{user.followings}}</strong> Follower</span>
                            <span class="mr-1 d-block"><strong>{{user.tweetnumber}}</strong> Tweets</span>
                        </div>
                    </div>
                    <CRow>
                        <CCol class="mt-3">
                            <TagSelect v-model="selectedTags" @select="addTag" @remove="removeTag" :loading="tagsLoading"/>
                        </CCol>
                    </CRow>
                </CCardBody>
            </CCard>
            <CCard v-else>
                <CCardBody>
                    <div class="text-center">
                        <CSpinner grow/>
                    </div>
                </CCardBody>
            </CCard>
            <CCard v-if="user">
                <CButton
                        @click="detailsCardCollapse = !detailsCardCollapse"
                        tag="button"
                        block
                        class="text-left shadow-none card-header"
                >
                    RAW User Data
                </CButton>
                <CCollapse :show="detailsCardCollapse">
                    <CCardBody class="m-1">
                        <pre>{{user}}</pre>
                        <CButton @click="deleteModal = true" color="danger">
                            Delete User
                        </CButton>
                        <CModal title="ATTENTION, User deletion can't be undone!" color="danger" :show.sync="deleteModal">
                            Are you sure you really want to delete this User? All Tweets from this User will also be deleted.<br>
                            <strong>This Action can not be undone!</strong>
                            <template #footer>
                                <CButton @click="deleteModal = false" color="success">
                                    NO, take me back to safety!
                                </CButton>
                                <CButton @click="deleteUser" color="danger">
                                    YES, i am sure!
                                </CButton>
                            </template>

                        </CModal>
                    </CCardBody>
                </CCollapse>
            </CCard>
        </CCol>
        <CCol class="col-xxl-6 col-12">
            <CCard>
                <CCardHeader>
                    <strong class="text-value-lg mr-2">Tweets</strong>
                </CCardHeader>
                <CCardBody style="max-height: 74vh; overflow-y: auto">
                    <div class="text-center">
                        <CSpinner grow v-if="!tweets.length"/>
                    </div>
                    <CCard v-for="(tweet, key) in tweets" :key="key" accent-color="secondary" :class="getTweetCardClasses(tweet)">
<!--                        <CCardHeader v-if="tweet.extras && tweet.extras.similar_tweet_id">-->
<!--                            <pre>{{tweet.extras}}</pre>-->
<!--                        </CCardHeader>-->
                        <CCardBody>
                            {{tweet.text}}
                        </CCardBody>
                        <CCardFooter :class="getTweetCardClasses(tweet)">
                            <div class="d-flex justify-content-between">
                                <div>
                                    <CIcon name="cil-heart" class="mr-2"></CIcon>
                                    <strong v-if="tweet.likes">{{tweet.likes}}</strong>{{tweet.likes ? '' : 0}}
                                </div>
                                <div>
                                    <CIcon name="cil-loop" class="mr-2"></CIcon>
                                    <strong v-if="tweet.retweet">{{tweet.retweet}}</strong>{{tweet.retweet ? '' : 0}}
                                </div>
                                <div>
                                    <CIcon name="cil-double-quote-sans-left" class="mr-2"></CIcon>
                                    <strong v-if="tweet.quote">{{tweet.quote}}</strong>{{tweet.quote ? '' : 0}}
                                </div>
                                <div>
                                    <CIcon name="cil-comment-square" class="mr-2"></CIcon>
                                    <strong v-if="tweet.reply">{{tweet.reply}}</strong>{{tweet.reply ? '' : 0}}
                                </div>
                                <div>
                                    <!--                                    <div class="small">erstellt {{formatDateDistanceToNow(tweet.created)}}</div>-->
                                    <CBadge color="dark" shape="pill" :href="'https://twitter.com/'+tweet.screen_name+'/status/'+tweet.tweet_id" target="_blank">{{formatDateString(tweet.created)}}</CBadge>
                                </div>
                            </div>
                        </CCardFooter>

                    </CCard>
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";
    import {format, formatDistanceToNow, parseISO, isValid} from 'date-fns'
    import {de} from 'date-fns/locale'
    import {twitterService} from "../../_services/twitter.service";

    import TagSelect from "./modules/TagSelect";

    export default {
        name: "User",
        components: {
            TagSelect
        },
        data() {
            return {
                detailsCardCollapse: false,
                selectedTags: [],
                tagsLoadingCounter: 0,
                tweets: [],
                deleteModal: false
            }
        },
        props: {
            id: String,
        },
        methods: {
            ...mapActions([
                'requestCurrentTwitterUsers',
                'addTwitterTagUserConnection',
                'delTwitterTagUserConnection',
                'requestTwitterTweets',
                'addToast',
            ]),
            ...mapMutations([
                'setCurrentUsersNeedReloading',
            ]),
            getDateInFormat(date, formatstr) {
                if (typeof date === "string") {
                    return format(new Date(date), formatstr, {locale: de})
                }
                return "-"
            },
            getFullProfilePicUrl(url) {
                return url.replace('_normal', '');
            },
            addTag(tagName) {
                console.log("select", tagName);
                let thisTag = this.getCurrentTwitterTags.tags.find(item => {
                    return item.name === tagName
                });
                if (thisTag) {
                    this.tagsLoading = true;
                    this.addTwitterTagUserConnection({"tagId": thisTag.tag_id, "userId": this.id}).then((res) => {
                        console.log("added Tag to user:", tagName, res);
                        this.setCurrentUsersNeedReloading(true);
                        this.tagsLoading = false;
                    });
                }
            },
            removeTag(tagName) {
                let thisTag = this.getCurrentTwitterTags.tags.find(item => {
                    return item.name === tagName
                });
                if (thisTag) {
                    this.tagsLoading = true;
                    this.delTwitterTagUserConnection({"tagId": thisTag.tag_id, "userId": this.id}).then((res) => {
                        console.log("deleted Tag from user:", tagName, res);
                        this.setCurrentUsersNeedReloading(true);
                        this.tagsLoading = false;
                    });
                }
            },
            formatDateDistanceToNow(date) {
                return formatDistanceToNow(date, {locale: de, addSuffix: true})
            },
            formatDateString(date) {
                return format(date, 'p, P', {locale: de})
            },
            getTweetCardClasses(tweet){
                if(tweet.deleted){
                    if(tweet.extras && tweet.extras.similar_tweet_id){
                        return "bg-gradient-warning text-white"
                    }else{
                        return "bg-gradient-danger text-white"
                    }
                }
            },
            deleteUser() {
                twitterService.delUser(this.id).then(res => {
                    if (res.status === 200) {
                        this.deleteModal = false;
                        this.addToast({text: 'User successfully deleted!', color: 'success'});
                        this.setCurrentUsersNeedReloading(true);
                        this.$router.go(-1)
                    }
                }).catch(error => {
                    this.deleteModal = false;
                    this.addToast({text: 'Error while deleting User', color: 'danger'});
                });
            }
        },
        computed: {
            ...mapGetters([
                'getCurrentTwitterUsers',
                'getCurrentTwitterTags'
            ]),
            tagsLoading: {
                get() {
                    return Boolean(this.tagsLoadingCounter);
                },
                set(newVal) {
                    if (newVal) {
                        this.tagsLoadingCounter = this.tagsLoadingCounter + 1;
                    } else {
                        this.tagsLoadingCounter = this.tagsLoadingCounter - 1;
                    }
                }

            },
            user() {
                if (this.getCurrentTwitterUsers.length === 0) {
                    this.requestCurrentTwitterUsers();
                }
                let thisUser = this.getCurrentTwitterUsers.find(item => {
                    return item.id === this.id
                });
                if (thisUser) this.selectedTags = thisUser.tags;
                return thisUser
            }
        },
        mounted() {
        },
        activated() {
            this.requestTwitterTweets({limit: 500, user_ids: this.id, include_retweets: 0}).then((res) => {
                this.tweets = res.data.tweets;
            });
        },
        deactivated() {
            //console.log("User deactivated");
        }
    }
</script>

<style scoped>

</style>
